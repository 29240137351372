import Button, { Kind } from 'components/Button';
import Flex, { Direction, Horizontal } from 'components/Flex';
import Icon, { Type, Size, Color } from 'components/Icon';
import { getClass, getTestId } from 'helpers/components';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AnimateHeight from 'react-animate-height';
import { NavLink, useLocation } from 'react-router-dom';
import { Index as Routes } from 'routes';
import { fetchApi } from 'helpers/fetching';
import FetchMethod from 'types/fetchMethod';
import { sidebarComponentName, SidebarConfigurationItem } from '.';

type Props = {
  sidebarExpanded?: boolean;
} & SidebarConfigurationItem;

export enum States {
  active = 'active',
}

const ITEM_LABEL_DOM_KEY = 'item-label';
const ITEM_DOM_KEY = 'item';
const ITEM_WRAPPER_DOM_KEY = 'item-wrapper';
const SUB_EXPAND_TRIGGER_DOM_KEY = 'expand-trigger';

const SidebarItem = (props: Props): ReactElement | null => {
  const {
    linkTo = '',
    iconType,
    show,
    text,
    fileRoute = '',
    filePath = '',
    subItems = [],
    sidebarExpanded,
    size = Size.large,
    customClass,
  } = props;

  const location = useLocation();

  const pathname = useMemo(() => location.pathname, [location]);

  const hasSubItems = useMemo(
    () => subItems.some((item) => item.show),
    [subItems],
  );

  const [deployed, setDeployed] = useState(false);

  const isRouteMatch = useCallback(
    (route: string): boolean =>
      !!pathname.match(new RegExp(`^${route}/?$`, 'g')),
    [pathname],
  );

  const subItemRoutes = useMemo(
    () => subItems.map(({ linkTo: link }) => link),
    [subItems],
  );

  const openFileInNewWindow = async (): Promise<void> => {
    try {
      const response = await fetchApi({
        endpoint: `${Routes.SEGMENT_FILES}/${fileRoute}`,
        method: FetchMethod.GET,
        isDownload: true,
      });

      const blob = new Blob(response.data as BlobPart[], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error opening file:', error);
    }
  };

  useEffect(() => {
    if (!hasSubItems) return;
    if (!sidebarExpanded) setDeployed(false);
    else if (subItemRoutes.includes(pathname)) setDeployed(true);
  }, [hasSubItems, linkTo, pathname, sidebarExpanded, subItemRoutes]);

  const toggleDeployed = (): void => setDeployed((p) => !p);

  if (!show) return null;

  const sidebarItemLabelClass = getClass(sidebarComponentName, {
    concat: [ITEM_LABEL_DOM_KEY],
    boolean: [{ state: isRouteMatch(linkTo), class: 'active' }],
  });
  const sidebarItemClass = getClass(sidebarComponentName, {
    concat: [ITEM_DOM_KEY],
  });
  const sidebarItemWrapperClass = getClass(sidebarComponentName, {
    concat: [ITEM_WRAPPER_DOM_KEY],
  });
  const sidebarSubExpandTriggerClass = getClass(sidebarComponentName, {
    concat: [SUB_EXPAND_TRIGGER_DOM_KEY],
  });
  const sidebarItemFileTestId = getTestId(sidebarComponentName, 'file');

  return (
    <li className={sidebarItemClass}>
      <Flex direction={Direction.column} horizontal={Horizontal.left}>
        <Flex horizontal={Horizontal.left}>
          {filePath && (
            <a
              className={sidebarItemWrapperClass}
              href={filePath}
              target="_blank"
              rel="noopener noreferrer"
              data-testid={sidebarItemFileTestId}
            >
              {iconType && (
                <span className="icon-wrapper d-flex justify-content-center">
                  <Icon
                    testId="sidebarItem"
                    type={iconType}
                    size={size}
                    color={Color.gray200}
                    customClass={customClass}
                  />
                </span>
              )}
              <span className={sidebarItemLabelClass}>{text}</span>
            </a>
          )}
          {!filePath && !fileRoute && (
            <NavLink
              to={linkTo}
              className={(navData) => (navData.isActive ? States.active : '')}
            >
              <Flex horizontal={Horizontal.left}>
                {iconType && (
                  <span className="icon-wrapper d-flex justify-content-center">
                    <Icon
                      testId="sidebarItem"
                      type={iconType}
                      size={size}
                      color={Color.gray200}
                      customClass={customClass}
                    />
                  </span>
                )}
                <span className={sidebarItemLabelClass}>{text}</span>
              </Flex>
            </NavLink>
          )}
          {fileRoute && (
            <span className={sidebarItemWrapperClass}>
              <Button
                onClick={openFileInNewWindow}
                kind={Kind.icon}
                data-testid={sidebarItemFileTestId}
              >
                <Flex horizontal={Horizontal.left}>
                  {iconType && (
                    <span className="icon-wrapper d-flex justify-content-center">
                      <Icon
                        testId="sidebarItem"
                        type={iconType}
                        size={size}
                        color={Color.gray200}
                        customClass={customClass}
                      />
                    </span>
                  )}
                  <span className={sidebarItemLabelClass}>{text}</span>
                </Flex>
              </Button>
            </span>
          )}
          {hasSubItems && (
            <span
              className={`${sidebarSubExpandTriggerClass} ${
                deployed ? 'deployed' : ''
              }`}
            >
              <Button
                onClick={toggleDeployed}
                kind={Kind.icon}
                iconSize={Size.micro}
                iconType={Type.carrot}
                iconColor={Color.gray200}
                testId="expandTrigger"
              />
            </span>
          )}
        </Flex>
      </Flex>
      {hasSubItems && (
        <AnimateHeight duration={300} height={deployed ? 'auto' : 0}>
          {subItems.map((subItem) => {
            const sidebarSubItemLabelClass = getClass(sidebarComponentName, {
              concat: [ITEM_LABEL_DOM_KEY],
              boolean: [
                { state: isRouteMatch(subItem.linkTo ?? ''), class: 'active' },
              ],
            });
            return (
              subItem.show && (
                <div
                  className={`${sidebarItemClass} subItem`}
                  key={subItem.text}
                >
                  {subItem.linkTo && (
                    <NavLink to={subItem.linkTo}>
                      <span className={sidebarSubItemLabelClass}>
                        {subItem.text}
                      </span>
                    </NavLink>
                  )}
                </div>
              )
            );
          })}
        </AnimateHeight>
      )}
    </li>
  );
};

export default SidebarItem;
